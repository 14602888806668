import React from 'react';

const CardInfo = (props) => {
    return (<>
        <div class="modal booking_popup fade" id="cardInfo" tabindex="-1" aria-labelledby="yourInfoLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body text-center">
                        <h3>Payment Info</h3>
                        <div className="row">
                            <div className="col-12">
                                <input className='form-control' type="text" name='cardNum' value={props.card} onChange={props.handleForm} placeholder='Credit Card Number' required />
                            </div>
                            <div className="col-6">
                                <input className='form-control' type="date" name='cardExpiry' value={props.expiry} onChange={props.handleForm} placeholder='Expiration' required />
                            </div>
                            <div className="col-6">
                                <input className='form-control' type="text" name='cardCvv' value={props.cvv} onChange={props.handleForm} placeholder='CVV' required />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-center">
                        <button type="button" class="btn btn_site" data-bs-toggle="modal" data-bs-target="#deliveryReview" onClick={props.next} disabled={props.btnstate ? false : true}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default CardInfo;