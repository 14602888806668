import React from 'react';

const YourInfo = (props) => {
    return (<>
        <div class="modal booking_popup fade" id="yourInfo" tabindex="-1" aria-labelledby="yourInfoLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body text-center">
                        <h3>Your Information</h3>
                        <div className="row">
                            <div className="col-6">
                                <input className='form-control' type="text" name='firstName' value={props.fname} onChange={props.handleForm} placeholder='First Name' required />
                            </div>
                            <div className="col-6">
                                <input className='form-control' type="text" name='lastName' value={props.lname} onChange={props.handleForm} placeholder='Last Name' required />
                            </div>
                            <div className="col-12">
                                <input className='form-control' type="text" name='cellPhn' value={props.phone} onChange={props.handleForm} placeholder='Cell Phone' required />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-center">
                        <button type="button" class="btn btn_site" data-bs-toggle="modal" data-bs-target="#otpConfirm" disabled={props.btnstate ? false : true}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default YourInfo;