import React from 'react';

const PickupConfirmed = () => {
    return (<>
        <div className="mob_screens">
            <div className="container">
                <img src="assets/images/check_circle.svg" alt="Check Mark Circle" className='checkMark' />
                <h3>Confirmed!</h3>
                <p className='statement'>We will send you a text when we are on our way.</p>
            </div>
        </div>
    </>);
}

export default PickupConfirmed;