import React from 'react';

const GreatNews = (props) => {
    return (<>
        <div class="modal booking_popup fade" id="greatNews" tabindex="-1" aria-labelledby="greatNewsLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <h3>Great news!</h3>
                        <p>We still have time to deliver today and keep you cool!</p>
                        <div className="row justify-content-between align-items-center">
                        <div className="col-12 d-md-none">
                                <div className="quantity mw-100">
                                    <button className='btn' type='button' onClick={props.decrease}>-</button>
                                    <input type="text" value={props.quantity} name='quantity' onChange={props.FormChange} />
                                    <button className='btn' type='button' onClick={props.increase}>+</button>
                                </div>
                            </div>
                            <div className="col-auto icon_info">
                                <p>Delivery Fee</p>
                                <img src="assets/images/info_icon.svg" alt="info icon" />
                                <div className="delv_info">
                                    <h4>Order Placed</h4>
                                    <div className="portion">
                                        <p>9AM to 5PM</p>
                                        <p>Delivery $20</p>
                                    </div>
                                    <div className="portion">
                                        <p>5PM to 8PM</p>
                                        <p>Delivery $30</p>
                                    </div>
                                    <div className="portion">
                                        <p>8PM to 11PM</p>
                                        <p>Delivery $40</p>
                                    </div>
                                    <div className="portion">
                                        <p>Fri/Sat/Sun</p>
                                        <p>Additional $10</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto">
                                <p>$20</p>
                            </div>
                        </div>
                        <div className="row justify-content-between align-items-center">
                            <div className="col-auto">
                                <p>Rooms</p>
                            </div>
                            <div className="col-auto d-none d-md-block">
                                <div className="quantity">
                                    <button className='btn' type='button' onClick={props.decrease}>-</button>
                                    <input type="text" value={props.quantity} name='quantity' onChange={props.FormChange} />
                                    <button className='btn' type='button' onClick={props.increase}>+</button>
                                </div>
                            </div>
                            <div className="col-auto">
                                <p>$105</p>
                            </div>
                        </div>
                        <div className="row row3 justify-content-between align-items-center">
                            <div className="col-auto">
                                <p>Total</p>
                            </div>
                            <div className="col-auto">
                                <p><b>$125</b></p>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-center">
                        <button type="button" class="btn btn_site" data-bs-toggle="modal" data-bs-target="#yourInfo">Order Now</button>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default GreatNews;