import React from 'react';

const StayCoolTonight = () => {
    return (<>
        <section className='stay_cool'>
            <div className="container">
                <div className="section-title text-center">
                    <h2>Stay Cool Tonight!</h2>
                    <button className='btn btn_site' data-bs-toggle="modal" data-bs-target="#confirmAddress">Order Now!</button>
                </div>
            </div>
        </section>
    </>);
}

export default StayCoolTonight;
