import React from 'react';

const EmailAddress = (props) => {
    return (<>
        <div class="modal booking_popup fade" id="emailAddress" tabindex="-1" aria-labelledby="emailAddressLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body text-center">
                        <h3>E-mail</h3>
                        <input className='form-control' type="email" name='email' placeholder='Email' required />
                    </div>
                    <div class="modal-footer justify-content-center">
                        <button type="button" class="btn btn_site">E-mail Receipt</button>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default EmailAddress;