import React from 'react';

const OrderSuccess = (props) => {
    return (<>
        <div class="modal booking_popup fade" id="orderSuccess" tabindex="-1" aria-labelledby="yourInfoLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <h3>{props.title}</h3>
                        <p className='p-0 mt-4' style={{lineHeight : '1.3'}}>{props.message}</p>
                    </div>
                    <div class="modal-footer justify-content-center">
                        <button type="button" class="btn btn_site" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default OrderSuccess;