import React from 'react';

const Banner = () => {
    return (<>
        <section className='banner'>
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-lg-6 txt">
                        <h1>Broken AC? <br /> Don’t Sweat it!</h1>
                        <p className='subtitle'>We can deliver 1 to 6 portable upright AC units that can provide the comfort you need!</p>
                        <button className='btn btn_site' data-bs-toggle="modal" data-bs-target="#confirmAddress">Book Now!</button>
                    </div>
                    <div className="col-lg-5">
                        <div className="img">
                            <img src="assets/images/bannerFeature.png" alt="Girl in Room" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>);
}

export default Banner;