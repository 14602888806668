import React from 'react';

const DeliveryReview = (props) => {
    return (<>
        <div class="modal booking_popup fade" id="deliveryReview" tabindex="-1" aria-labelledby="yourInfoLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <h3>OK, we will be delivering today to</h3>
                        <div className="row align-items-end mt-3">
                            <div className="col-md-8 info">
                                <p><b>Address:</b></p>
                                <p>{props.address}</p>
                                <p><b>Cell Phone:</b></p>
                                <p>{props.cell}</p>
                            </div>
                            <div className="col-md-4 units">
                                <p>3 Units</p>
                                <h3>$125</h3>
                                <p>Last Night</p>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-center">
                        <button type="button" class="btn btn_site" data-bs-toggle="modal" data-bs-target="#orderSuccess">Charge Card</button>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default DeliveryReview;