import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import HeaderCta from './HeaderCta';

const Header = () => {
    let url = useLocation()
    return (<>
        <header>
            <nav className={`navbar ${url.pathname !== '/' ? 'gradient' : ''}`}>
                <div className={`container ${url.pathname !== '/' ? 'justify-content-center' : 'justify-content-between' }`}>
                    <Link class="navbar-brand" to="/"><img src="assets/images/logo.svg" alt="Brand Logo" /></Link>
                    {url.pathname === '/' ? <HeaderCta /> : null }
                </div>
            </nav>
        </header>
    </>);
}

export default Header;