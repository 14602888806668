import './assets/scss/global.scss';
import Routers from './Routes/Routers';
import Header from './Components/Global/Header';

function App() {
  return (<>
    <Header />
    <Routers />
  </>);
}

export default App;