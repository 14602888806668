import {Route, Routes} from "react-router-dom";
import Main from "../Components/Main";
import AboutTechnician from "../Components/Other/AboutTechnician";
import DirectConfirmPickup from "../Components/Other/DirectConfirmPickup";
import ExtendNight from "../Components/Other/ExtendNight";
import FinalBill from "../Components/Other/FinalBill";
import PickupConfirmed from "../Components/Other/PickupConfirmed";
import SchedulePickup from "../Components/Other/SchedulePickup";
// Screens

const Routers = () => {
    return (<>
        <Routes>
            <Route path="/" exact element={<Main />} />
            <Route path="/confirmpickup" exact element={<DirectConfirmPickup />} />
            <Route path="/confirmed" exact element={<PickupConfirmed />} />
            <Route path="/shcedule-pickup" exact element={<SchedulePickup />} />
            <Route path="/extend" exact element={<ExtendNight />} />
            <Route path="/finalbill" exact element={<FinalBill />} />
            <Route path="/about-technician" exact element={<AboutTechnician />} />
        </Routes>
</>);
}
export default Routers;