import React from 'react';
import { Link } from 'react-router-dom';

const SchedulePickup = () => {
    return (<>
        <div className="mob_screens">
            <div className="container">
                <h3>Schedule Pick up</h3>
                <p>Tuesday, Aug 16</p>
                <p className='my-2'>Between</p>
                <p className='mb-2'>2PM to 2:30PM</p>
                <Link to='/confirmed' className='btn btn_site'>Confirm Pickup</Link>
                <Link to='/extend' className='btn btn_site outlined'>Extend 1 Night</Link>
            </div>
        </div>
    </>);
}

export default SchedulePickup;