import React from 'react';
import TwoColumns from '../Global/TwoColumns';

const WhyUs = () => {
    return (<>
        <section className='whyus'>
            <div className="container">
                <div className="section-title text-center">
                    <h2>Why use us?</h2>
                </div>
                <div className="wrapper">
                    {/* Row 1 */}
                    {/* if we want text on the left side and image on the right */}
                    {/* add this class to rowClass prop */} {/* flex-md-row flex-column-reverse */}
                    <TwoColumns 
                        rowClass='row1 flex-md-row flex-column-reverse justify-content-between align-items-center'
                        colClass1='col-md-4'
                        colClass2='col-md-7'
                        imgSrc='assets/images/sms_slert.svg'
                        imgDesc='Sms Alert Icon'
                        title='Charge as we go'
                        description='We only charge one night at a time Receive easy to understand text alerts to pickup or extend your order.'
                    />
                    {/* if we want text on the right side and image on the left */}
                    {/* add this class to rowClass prop */} {/* flex-md-row-reverse flex-column-reverse */}
                    {/* Row 2 */}
                    <TwoColumns 
                        rowClass='row2 flex-md-row-reverse flex-column-reverse justify-content-between align-items-center'
                        colClass1='col-md-4'
                        colClass2='col-md-7'
                        imgSrc='assets/images/moon.svg'
                        imgDesc='Moon'
                        title='Delivery until midnight'
                        description='AC doesn’t break 9 to 5. Order as late as 11pm and we will deliver and setup same day.'
                    />
                    {/* Row 3 */}
                    <TwoColumns 
                        rowClass='row3 flex-md-row flex-column-reverse justify-content-between align-items-center'
                        colClass1='col-md-4'
                        colClass2='col-md-7'
                        imgSrc='assets/images/safe_delivery.svg'
                        imgDesc='Safe Delivery Badge'
                        title='Safe Shield Delivery'
                        description='Profile picture and background check certificate of your technician when they are dropping off and picking up.'
                    />
                    {/* Row 4 */}
                    <TwoColumns 
                        rowClass='row4 flex-md-row-reverse flex-column-reverse justify-content-between align-items-center'
                        colClass1='col-md-4'
                        colClass2='col-md-7'
                        imgSrc='assets/images/clean_sanitize.svg'
                        imgDesc='Clean and Sanitized'
                        title='Cleaned and Sanitized'
                        description='We wash the filters and wipe down the entire machine with disinfectant between uses.'
                    />
                </div>
            </div>
        </section>
    </>);
}

export default WhyUs;