import React from 'react';

const ConfirmAddress = (props) => {
    return (<>
        <div class="modal booking_popup fade" id="confirmAddress" tabindex="-1" aria-labelledby="confirmAddressLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body text-center">
                        <h3>Confirm Delivery Address</h3>
                        <input className='form-control' type="text" name='address' value={props.value} onChange={props.handleForm} placeholder='Confirm Delivery Address' required />
                    </div>
                    <div class="modal-footer justify-content-center">
                        <button type="button" class="btn btn_site" data-bs-toggle="modal" data-bs-target="#greatNews" disabled={props.btnstate ? false : true}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default ConfirmAddress;