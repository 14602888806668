import React from 'react';
import TwoColumns from '../Global/TwoColumns';

const HowItWorks = () => {
    return (<>
        <section className='how_works'>
            <div className="container">
                <div className="section-title text-center">
                    <h2>How does it work?</h2>
                </div>
                <div className="wrapper">
                {/* if we want text on the left side and image on the right */}
                    {/* add this class to rowClass prop */} {/* flex-md-row-reverse flex-column-reverse */}
                    {/* Row 1 */}
                    <TwoColumns
                        rowClass='row1 flex-md-row-reverse flex-column-reverse justify-content-between align-items-center'
                        colClass1='col-md-6'
                        colClass2='col-lg-5 col-md-6'
                        imgSrc='assets/images/delivery_car.png'
                        imgDesc='Delivery Truck'
                        title='We deliver to your home'
                        description='You will receive messages on your cell estimating our arrival time.'
                    />
                    {/* if we want text on the right side and image on the left */}
                    {/* add this class to rowClass prop */} {/* flex-md-row flex-column-reverse */}
                    {/* Row 2 */}
                    <TwoColumns
                        rowClass='row2 flex-md-row flex-column-reverse justify-content-between align-items-center'
                        colClass1='col-lg-4 col-md-5'
                        colClass2='col-lg-5 col-md-6'
                        imgSrc='assets/images/setup_unit.png'
                        imgDesc='Unit Setup'
                        title='Setup each unit'
                        description='Must be next to a window and within 10 feet  of a standard 110V outlet.'
                    />
                    {/* Row 3 */}
                    <TwoColumns
                        rowClass='row3 flex-md-row-reverse flex-column-reverse justify-content-between align-items-center'
                        colClass1='col-lg-4 col-md-5'
                        colClass2='col-lg-5 col-md-6'
                        imgSrc='assets/images/mob_mockup.png'
                        imgDesc='Mobile Mockup'
                        title='Receive Updates'
                        description='We will send you a text message and ask if you would like to extend or have the unit picked up. Keep until your AC is fixed.'
                    />
                </div>
            </div>
        </section>
    </>);
}

export default HowItWorks;