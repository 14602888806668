import React from 'react';
import EmailAddress from '../Popups/EmailAddress';

const FinalBill = () => {
    return (<>
        <div className="mob_screens">
            <div className="container">
                <p>Thank you for using StayCoolTonight.com</p>
                <p className='mt-2'>We have successfully picked up our units.</p>
                <h3 className='my-2'>Number of Nights</h3>
                <h2>2</h2>
                <p className='statement'>Charge you will see on your credit card statement.</p>
                <div className="row justify-content-between align-items-center mb-2">
                    <div className="col-auto">
                        <p>8/16/2022</p>
                    </div>
                    <div className="col-auto">
                        <p><b>$135</b></p>
                    </div>
                </div>
                <div className="row justify-content-between align-items-center mb-2">
                    <div className="col-auto">
                        <p>8/16/2022</p>
                    </div>
                    <div className="col-auto">
                        <p><b>$135</b></p>
                    </div>
                </div>
                <button className='btn btn_site' data-bs-toggle="modal" data-bs-target="#emailAddress">E-mail Receipt</button>
            </div>
        </div>
        <EmailAddress />
    </>);
}

export default FinalBill;