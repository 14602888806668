import React, { useState } from 'react';
import CardInfo from '../Popups/CardInfo';
import ConfirmAddress from '../Popups/ConfirmAddress';
import DeliveryReview from '../Popups/DeliveryReview';
import GreatNews from '../Popups/GreatNews';
import OrderSuccess from '../Popups/OrderSuccess';
import OtpConfirm from '../Popups/OtpConfirm';
import YourInfo from '../Popups/YourInfo';

const CalculatorSection = () => {
    const [otp, setOtp] = useState('');
    const [unitQuantity, setUnitQuantity] = useState(1);
    const [deliveryDetail, setDeliveryDetail] = useState({
        firstName : '',
        lastName : '',
        cellPhn : '',
        email : '',
        otpVal : '',
        dateYouNeed: '',
        address: '',
        cardNum : '',
        cardExpiry : '',
        cardCvv : '',
        quantity: 1,
        deliveryFee1Night : 20,
        deliveryFee2Night : 0,
        acRent1Night : 150,
        acRent2Night : 120,
        btnDisable: true
    });
    const formSubmit = (e) => {
        e.preventDefault();
    }
    const decreaseQuantity = () => {
        if (deliveryDetail.quantity !== 1) {
            setDeliveryDetail((pre) => {
                return { ...pre, quantity: pre.quantity-1 }
            });
        }
    }
    const increaseQuantity = () => {
        setDeliveryDetail((pre) => {
            return { ...pre, quantity: pre.quantity+1 }
        });
    }
    const handleFormChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        setDeliveryDetail((pre) => {
            return { ...pre, [name]: value }
        });
        // console.log(deliveryDetail);
    }
    const checkVal = () => {
        setDeliveryDetail((pre) => {
            return {...pre, otpVal : otp}
        })
        console.log(deliveryDetail);
    }
    return (<>
        <section className="cal_section">
            <div className="container">
                <div className="wrapper">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-3">
                            <div className="section-title">
                                <h2>Transparent pricing</h2>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7">
                            <div className="calculator">
                                <form onSubmit={formSubmit}>
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <div class="form_group">
                                                <label for="dateYouNeed" class="form-label">Date you need AC</label>
                                                <input type="date" class="form-control" id="dateYouNeed" value={deliveryDetail.dateYouNeed} name='dateYouNeed' onChange={handleFormChange} required />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div class="form_group">
                                                <label for="address" class="form-label">Delivery Address</label>
                                                <div className="position-relative">
                                                    <input type="text" class="form-control" id="address" name='address' value={deliveryDetail.address} onChange={handleFormChange} required />
                                                    <span className='icon'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14.619" height="18" viewBox="0 0 14.619 18"><path id="location" d="M15.31,12.909a1.6,1.6,0,0,0,1.131-2.73,1.6,1.6,0,0,0-2.261,2.261A1.541,1.541,0,0,0,15.31,12.909Zm0,7.561A26.754,26.754,0,0,0,19.8,15.456a7.607,7.607,0,0,0,1.451-3.963,5.946,5.946,0,0,0-1.725-4.409A5.753,5.753,0,0,0,15.31,5.371,5.753,5.753,0,0,0,11.1,7.084a5.946,5.946,0,0,0-1.725,4.409,7.5,7.5,0,0,0,1.485,3.963A29.052,29.052,0,0,0,15.31,20.47ZM15.332,22a.934.934,0,0,1-.3-.046.809.809,0,0,1-.251-.137A29.135,29.135,0,0,1,9.69,16.266,8.792,8.792,0,0,1,8,11.492a7.066,7.066,0,0,1,2.2-5.459,7.427,7.427,0,0,1,10.211,0,7.066,7.066,0,0,1,2.2,5.459,8.792,8.792,0,0,1-1.69,4.774,29.135,29.135,0,0,1-5.094,5.551.847.847,0,0,1-.24.137A.763.763,0,0,1,15.332,22ZM15.31,11.492Z" transform="translate(-8 -4)" fill="#848484" /></svg>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <p>Number of portable AC units needed</p>
                                        </div>
                                        {/* Quantity */}
                                        <div className="col-6">
                                            <div className="quantity">
                                                <button className='btn' type='button' onClick={decreaseQuantity}>-</button>
                                                <input type="text" value={deliveryDetail.quantity} name='quantity' onChange={handleFormChange} />
                                                <button className='btn' type='button' onClick={increaseQuantity}>+</button>
                                            </div>
                                        </div>
                                        <div className="col-12 detail night">
                                            <div className="row align-items-center">
                                                {/* only for space */}
                                                <div className="col-4"></div>
                                                <div className="col-4">
                                                    <p>1 Night</p>
                                                </div>
                                                <div className="col-4">
                                                    <p>2 Nights</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 detail">
                                            <div className="row align-items-center">
                                                <div className="col-4 text-end">
                                                    <label>Delivery Fee</label>
                                                </div>
                                                <div className="col-4">
                                                    <p>${deliveryDetail.deliveryFee1Night}</p>
                                                </div>
                                                <div className="col-4">
                                                    <p>${deliveryDetail.deliveryFee2Night}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 detail mb-2">
                                            <div className="row align-items-center">
                                                <div className="col-4 text-end">
                                                    <label>AC Rental</label>
                                                </div>
                                                <div className="col-4">
                                                    <p>${deliveryDetail.acRent1Night}</p>
                                                </div>
                                                <div className="col-4">
                                                    <p>${deliveryDetail.acRent2Night}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 detail total">
                                            <div className="row align-items-center">
                                                <div className="col-4 text-end">
                                                    <label>Total</label>
                                                </div>
                                                <div className="col-4">
                                                    <p>${deliveryDetail.acRent1Night +  deliveryDetail.deliveryFee1Night}</p>
                                                </div>
                                                <div className="col-4">
                                                    <p>${deliveryDetail.acRent2Night + deliveryDetail.deliveryFee2Night}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 footer">
                                            <div className="row align-items-center">
                                                <div className="col-7">
                                                    <p>Your only billed 1 night at a time</p>
                                                </div>
                                                <div className="col-5">
                                                    <button type="submit" className='btn btn_site' data-bs-toggle="modal" data-bs-target="#yourInfo" disabled={deliveryDetail.dateYouNeed.length > 5 && deliveryDetail.address.length > 5 ? false : true}>Setup Delivery</button>
                                                </div>
                                                <div className="col-12 text-center mt-3">
                                                    <span>* 1 unit cools up to 250 SF or an average bedroom</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Popups --> */}
        <GreatNews 
            decrease={decreaseQuantity}
            increase={increaseQuantity}
            quantity={deliveryDetail.quantity}
            FormChange={handleFormChange}
        />
        <ConfirmAddress 
            value={deliveryDetail.address}
            handleForm={handleFormChange}
            btnstate={deliveryDetail.address.length > 5 ? true : false}
        />
        <YourInfo 
            fname={deliveryDetail.firstName}
            lname={deliveryDetail.lastName}
            phone={deliveryDetail.cellPhn}
            handleForm={handleFormChange}
            btnstate={deliveryDetail.firstName.length > 3 && deliveryDetail.lastName.length > 3 && deliveryDetail.cellPhn.length > 5 ? true : false}
        />
        <OtpConfirm 
            val={otp}
            handleOtp={setOtp}
            btnstate={otp.length === 6 ? true : false}
        />
        <CardInfo 
            card={deliveryDetail.cardNum}
            expiry={deliveryDetail.cardExpiry}
            cvv={deliveryDetail.cardCvv}
            handleForm={handleFormChange}
            next={checkVal}
            btnstate={deliveryDetail.cardNum.length >= 16 && deliveryDetail.cardExpiry.length > 4 && deliveryDetail.cardCvv.length === 3 ? true : false }
        />
        <DeliveryReview 
            address={deliveryDetail.address}
            cell={deliveryDetail.cellPhn}
        />
        <OrderSuccess 
            title="Thank You for your Order!"
            message="We will be texting you when we are in route. You can communicate with your StayCool rep via text at any time."
        />
    </>);
}

export default CalculatorSection;