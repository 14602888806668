import React from 'react';

const HeaderCta = () => {
    return (<>
    <div className='d-flex align-items-center flex-column-reverse flex-md-row'>
        <button className='btn btn_txt me-2 d-md-inline-block d-none' type='button'>Contractor Login</button>
        <button className='btn btn_site' type='button'>Check Status</button>
    </div>
    </>);
}

export default HeaderCta;