import React from 'react';

const ServiceBanner = () => {
    return (<>
        <section className='service_area'>
            <div className="container">
                <div className="section-title text-center">
                    <h2>Servicing the Florida Gulf Coast</h2>
                </div>
            </div>
        </section>
    </>);
}

export default ServiceBanner;