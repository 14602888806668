import React from 'react';

const TwoColumns = (props) => {
    return (<>
    {/* Row */}
    <div className={`row ${props.rowClass}`}>
    {/* Column 1 */}
        <div className={props.colClass1}>
            <div className={`img ${props.itemClass1}`}>
                <img src={props.imgSrc} alt={props.imgDesc} />
            </div>
        </div>
        {/* Column 2 */}
        <div className={props.colClass2}>
            <div className={`txt ${props.itemClass2}`}>
                <h3>{props.title}</h3>
                <p>{props.description}</p>
            </div>
        </div>
    </div>
    </>);
}

export default TwoColumns;