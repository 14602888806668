import React from 'react';
import OTPInput from 'otp-input-react';

const OtpConfirm = (props) => {
    return (<>
        <div class="modal booking_popup fade" id="otpConfirm" tabindex="-1" aria-labelledby="otpConfirmLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body text-center">
                        <h3>Enter the 6 digit number we sent you</h3>
                        {/* <input className='form-control' type="email" name='email' value={props.value} onChange={props.handleForm} placeholder='Confirm Delivery Address' required /> */}
                        <OTPInput className='inpOtp justify-content-center' value={props.val} onChange={props.handleOtp} name='otpVal' OTPLength={6} otpType="number" inputStyles={{width : '60px', height : '60px'}} disabled={false} secure />
                    </div>
                    <div class="modal-footer justify-content-center">
                        <button type="button" class="btn btn_site" data-bs-toggle="modal" data-bs-target="#cardInfo" disabled={props.btnstate ? false : true}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default OtpConfirm;