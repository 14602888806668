import React from 'react';

const AboutTechnician = () => {
    return (<>
        <div className="mob_screens">
            <div className="container">
                <img src="assets/images/tech_pic.png" alt="technician pic" className='tech_pic' />
                <h3>Tom Jones</h3>
                <p>Is in route and should arrive between</p>
                <p className='my-2'>2PM to 2:10PM</p>
                <button className='btn btn_site'>Message Technician</button>
            </div>
        </div>
    </>);
}

export default AboutTechnician;