import React from 'react';
import { Link } from 'react-router-dom';
import CardInfo from '../Popups/CardInfo';
import OrderSuccess from '../Popups/OrderSuccess';

const ExtendNight = () => {
    return (<>
        <div className="mob_screens">
            <div className="container">
                <h3>Tonight's Charge</h3>
                <h3>$135</h3>
                <p>On card ending in *5436</p>
                <button className='btn change' data-bs-toggle="modal" data-bs-target="#cardInfo">Change</button>
                <button className='btn btn_site' data-bs-toggle="modal" data-bs-target="#orderSuccess">Extend 1 Night</button>
                <Link to='/shcedule-pickup' className='btn btn_site outlined'>Cancel</Link>
                <p className='past'>Past Charges</p>
                <div className="row justify-content-between align-items-center">
                    <div className="col-auto">
                        <p>8/16/2022</p>
                    </div>
                    <div className="col-auto">
                        <p><b>$135</b></p>
                    </div>
                </div>
            </div>
        </div>
        <CardInfo />
        <OrderSuccess 
            title='Confirmed!'
            message='We will send text tomorrow to confirm pickup or extending equipment rental.'
        />
    </>);
}

export default ExtendNight;