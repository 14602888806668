import React from 'react';

const BetterForYou = () => {
    return (<>
        <section className='better'>
            <div className="container">
                <div className="section-title text-center">
                    <h2>Which sounds better to you?</h2>
                </div>
                <div className="wrapper">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-auto">
                            <div className="shadow_box">
                                <h3>Local Hotel</h3>
                                <ul>
                                    <li>Pack a suitcase</li>
                                    <li>No working kitchen</li>
                                    <li>Questionable “Clean” Sheets</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="vs">
                                <h3>VS</h3>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="shadow_box">
                                <h3>Stay Home</h3>
                                <ul>
                                    <li>Access to your Kitchen</li>
                                    <li>Cool Air waiting for repair man</li>
                                    <li>Sleep in your own bed</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="big shadow_box">
                                <p>Stay Cool at <br /> home starting at</p>
                                <h2><sup>$</sup>55</h2>
                                <p>Per night</p>
                                <p>Delivered and Setup</p>
                                <button className='btn btn_site' data-bs-toggle="modal" data-bs-target="#confirmAddress">Order Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>);
}

export default BetterForYou;