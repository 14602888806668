import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (<>
        <footer>
            <div className="wrapper">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-auto logo">
                            <Link to="/"><img src="assets/images/logo.svg" alt="Brand Logo" /></Link>
                        </div>
                        <div className="col-12 bar text-center">
                            <Link to="/" className='d-md-none'>Contracor Login</Link>
                            <Link to="/">Privacy Policy</Link>
                            <Link to="/">Terms</Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </>);
}

export default Footer;