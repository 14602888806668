import React from 'react';

const MapSection = () => {
    return (<>
        <section className='custom_map position-relative'>
            <div className="map_info">
            <div className="wrapper">
                <h3>We proudly serve the following areas</h3>
                <ul>
                    <li>St Pete</li>
                    <li>Ft Myers</li>
                    <li>Tampa</li>
                    <li>Cape Coral</li>
                    <li>Clearwater</li>
                    <li>Estero</li>
                    <li>Bradenton</li>
                    <li>Bonita</li>
                    <li>Sarasota</li>
                    <li>Springs</li>
                    <li>Venice</li>
                    <li>Naples</li>
                </ul>
            </div>
            </div>
            <img src="assets/images/map_img.png" alt="Map Img" />
        </section>
    </>);
}

export default MapSection;