import React from 'react';

const DirectConfirmPickup = () => {
    return (<>
        <div className="mob_screens">
            <div className="container">
                <p>Thank you for using StayCoolTonight.com</p>
                <h3>Number of Nights</h3>
                <h2>1</h2>
                <p className='statement'>Charge you will see on your credit card statement.</p>
                <div className="row justify-content-between align-items-center">
                    <div className="col-auto">
                        <p>8/16/2022</p>
                    </div>
                    <div className="col-auto">
                        <p><b>$135</b></p>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default DirectConfirmPickup;