import React from 'react';
import Banner from './Landing/Banner';
import BetterForYou from './Landing/BetterForYou';
import CalculatorSection from './Landing/CalculatorSection';
import HowItWorks from './Landing/HowItWorks';
import MapSection from './Landing/MapSection';
import ServiceBanner from './Landing/ServiceBanner';
import StayCoolTonight from './Landing/StayCoolTonight';
import WhyUs from './Landing/WhyUs';
import Footer from './Global/Footer';

const Main = () => {
    return (<>
        {/* Banner Section */}
        <Banner />
        {/* Bettor FOr You Section */}
        <BetterForYou />
        {/* Why Us Section */}
        <WhyUs />
        {/* Calculator Section */}
        <CalculatorSection />
        {/* How It Works Section */}
        <HowItWorks />
        {/* Service Area Banner */}
        <ServiceBanner />
        {/* Map Section */}
        <MapSection />
        {/* Stay Cool tonight */}
        <StayCoolTonight />
        {/* Footer */}
        <Footer />
    </>);
}

export default Main;